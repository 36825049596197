<template>
  <div class="overflow-scroll">
    <div style="min-width: 1155px">
      <a-card>
        <div class="table-operator">
          <a-form
            v-if="this.viewHasRight === true"
            layout="inline"
            class="feeEditLabelWidth95"
          >
            <a-row :gutter="0" style="line-height: 3.5">
              <a-col :md="7" :sm="24">
                <a-form-item label="店铺名称">
                  <a-input
                    v-model="queryParam.storeName"
                    @pressEnter="search"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="7" :sm="24">
                <a-form-item label="门店名称">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.facilityName"
                    :filter-option="filterOption"
                    @change="handleFacilityChange"
                    show-search
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in facilityList"
                      :key="item.facilityId"
                    >
                      {{ item.facilityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col
                :md="3"
                :sm="24"
                style="display: inline-block; margin-left: auto"
              >
                <span>
                  <a-button
                    style="margin-left: auto"
                    @click="() => ((queryParam = {}), this.search())"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div
          v-if="this.newHasRight === true"
          class="table-operator"
          style="margin-top: -8px"
        >
          <a-button type="primary" icon="plus" @click="handleNew"
            >新建</a-button
          >
        </div>
        <a-spin :spinning="loading">
          <a-table
            rowKey="storeId"
            :pagination="pagination"
            :columns="columns"
            :dataSource="data"
            :scroll="{ x: 800 }"
          >
            <!-- 饿了么 -->
            <span
              slot="elemeApiAuth"
              slot-scope="text, record"
              class="blueColor"
            >
              <template>
                <span>{{ showApiAuth(record.elemeApiAuth) }}</span>
              </template>
            </span>
            <!-- 美团 -->
            <span
              slot="meituanApiAuth"
              slot-scope="text, record"
              class="blueColor"
            >
              <template>
                <span>{{ showApiAuth(record.meituanApiAuth) }}</span>
              </template>
            </span>
            <!-- 操作 -->
            <span slot="action" slot-scope="text, record" class="blueColor">
              <template>
                <a v-if="viewHasRight === true" @click="handleDetail(record)"
                  >查看</a
                >&nbsp;&nbsp;
                <a v-if="editHasRight === true" @click="handleEdit(record)"
                  >编辑</a
                >&nbsp;&nbsp;
              </template>
            </span>
          </a-table>
          <token-pagination
            :page="page"
            :total="total"
            :pageSize="pageSize"
            @change="changePage"
          />
        </a-spin>
      </a-card>
    </div>
  </div>
</template>

<script>
  
import Bus from "../../bus";
import { tokenOnlyPagination } from "../../utils/pagination";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { hasPermissions } from "../../utils/auth";
import { OrganizationActions } from "../../utils/actions";
import TokenPagination from "../../components/Pagination/TokenPagination";
import { GET_STORES, RESET_STORES } from "../../store/modules/actions.type";

export default {
  name: "shopList",
  components: {
    TokenPagination
  },
  data() {
    return {
      loading: false,
      //店铺名称
      facilityList: [],
      queryParam: {
        storeName: "",
        facilityName: ""
      },
      //授权
      apiAuthTypes: [
        { id: 1, name: "未授权" },
        { id: 2, name: "已授权" }
      ],
      pagination: tokenOnlyPagination(),
      page: 1,
      pageSize: 20,
      // 表头
      columns: [
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName",
          scopedSlots: { customRender: "storeName" },
          fixed: "left"
        },
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
          scopedSlots: { customRender: "facilityName" }
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "饿了么授权",
          // dataIndex: "elemeApiAuth",
          key: "elemeApiAuth",
          scopedSlots: { customRender: "elemeApiAuth" }
        },
        {
          title: "美团外卖授权",
          // dataIndex: "meituanApiAuth",
          key: "meituanApiAuth",
          scopedSlots: { customRender: "meituanApiAuth" }
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "130px",
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ],
      form: this.$form.createForm(this)
    };
  },
  created() {
    this.getInformation();
    this.fetch();
    Bus.$on("getTarget", target => {
      this.fetch();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("stores", ["total", "stores"]),
    data: function() {
      return this.stores(this.page);
    },
    newHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_CREATE],
        this.authenticatedUser.actions
      );
    },
    viewHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_READ],
        this.authenticatedUser.actions
      );
    },
    editHasRight() {
      return hasPermissions(
        [OrganizationActions.STORE_EDIT],
        this.authenticatedUser.actions
      );
    }
  },
  watch: {
    page: {
      handler: "fetch"
    }
  },
  methods: {
    ...mapActions("stores", [GET_STORES, RESET_STORES]),
    // 分页
    changePage(page) {
      this.page = page;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.includes(input);
    },
    // 查询
    search() {
      this.resetStores();
      this.page = 1;
      this.fetch();
    },
    handleFacilityChange(val) {
      this.queryParam.facilityName = this.facilityList.find(
        facility => facility.facilityId === val
      ).facilityName;
      this.search();
    },
    // 列表接口
    fetch: async function() {
      this.loading = true;
      await this.getStores({
        ...this.queryParam,
        rows: this.pageSize,
        page: this.page
      });
      this.loading = false;
    },
    //获取所有枚举信息
    getInformation() {
      this.getFacility();
    },
    // 店铺名称
    getFacility() {
      axios({
        url:   this.$baseUrl + "/tenantStore/allFacility",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 新建
    handleNew(val) {
      this.$router.push({
        path: "/role/shopList/shopNew",
        query: {}
      });
    },
    // 详情
    handleDetail(val) {
      this.$router.push({
        path: "/role/shopList/shopDetail",
        query: { id: val.storeId }
      });
    },
    // 编辑
    handleEdit(val) {
      this.$router.push({
        path: "/role/shopList/shopEdit",
        query: { id: val.storeId }
      });
    },
    //授权
    showApiAuth(value) {
      let val = "";
      this.apiAuthTypes.forEach(item => {
        if (value === item.id) {
          val = item.name;
        }
      });
      return val;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
